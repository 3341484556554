import { HttpHeaders } from '@angular/common/http';

import { AUTH_HEADER_KEY, AUTH_PREFIX } from '../constants/auth';

/**
 * Compose authorization header.
 * @param token Authorization token.
 * @param headers HTTP headers.
 */
export function composeAuthorizationHeader(token: string, headers?: HttpHeaders): HttpHeaders {
	return headers ? appendAuthorizationHeader(headers, token) : createAuthorizationHeader(token);
}

/**
 * Create authorization header.
 * @param token Authorization token.
 */
function createAuthorizationHeader(token: string): HttpHeaders {
	return new HttpHeaders({
		[AUTH_HEADER_KEY]: `${AUTH_PREFIX} ${token}`,
	});
}

/**
 * Append authorization header to a list of `headers`.
 * @param headers HTTP headers.
 * @param token Authorization token.
 */
function appendAuthorizationHeader(headers: HttpHeaders, token: string): HttpHeaders {
	return headers.set(AUTH_HEADER_KEY, `${AUTH_PREFIX} ${token}`);
}
